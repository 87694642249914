import React, {useEffect, useState} from 'react';
import palette, {Hue} from "./customStyles/palette";
import {A, Block, SubTitle, Title, HR, ULColumns} from './customStyles/components';
import DisplayColorBoxes from './DisplayColorBoxes';

const inLogo = require('./asset/in-icon.png')

export default function App(): JSX.Element {

    const number = Math.ceil(Math.random() * 10) % 3;
    // const hue: Hue = number === 0 ? 'Blues' : number === 1 ? 'Reds' : 'Greens'

    const [hue] = useState<Hue>(number === 0 ? 'Blues' : number === 1 ? 'Reds' : 'Greens')
    //switch(number){case 0: return 'Blues'; case 1: return 'Reds'; case 2: return 'Greens'})

    console.log(number, hue)

    useEffect(() => {
        document.body.style.color = palette.Spread[hue].primary;
        document.body.style.backgroundColor = palette.Spread[hue].secondary;
    }, [])

    return (<>
        <header>
            <Title hue={hue}>Hello World</Title>
            <span>My name is Joshua Schoonover. I'm good at coding. Here are some of the things I have done.</span>
        </header>
        <main style={{
            color: palette.Spread[hue].primary,
            backgroundColor: palette.Spread[hue].secondary
        }}>
            <SubTitle hue={hue}>~Changes to the site in the coming month~</SubTitle>
            {/*<br/><br/><br/>*/}
            {/*<div style={{alignSelf: 'center', margin: '1rem auto 0 auto', width: 'min-content'}}>*/}
            {/*    <DataPlate/>*/}
            {/*</div>*/}
            {/*<br/>*/}

            <HR hue={hue} />

            <Block>
                <SubTitle hue={hue}>Trained with:</SubTitle>
                <ul>
                    <li>6 Month Full-Stack Developer track certificate with <A href="https://www.galvanize.com/">Galvanize</A></li>
                </ul>
            </Block>

            <Block>
                <SubTitle hue={hue}>Certifications:</SubTitle>
                <ul>
                    <li><A href="https://www.nga.mil/resources/GEOINT_Professional_Certification.html">NGA GEOINT</A> Professional Certification Fundamentals (GPC-F)</li>
                    <ul><li>April 2024</li></ul>
                </ul>
            </Block>

            <Block>
                <SubTitle hue={hue}>Work Experience:</SubTitle>
                <ul>
                    <li><A href="https://www.radiancetech.com/">Radiance Technologies</A><br/></li>
                        <ul>
                            <li>ESRI Developer</li>
                            <li>December 2024 to Current</li>
                        </ul>
                    
                    <li>City of Rochester<br/>
                    <A href="https://www.cityofrochester.gov/article.aspx?id=8589934839">Data and Digital Sevices</A> Department</li>
                    <ul>
                        <li>Internship as GIS and Software Consultant</li>
                        <li>April 2024 to June 2024</li>
                        <ul>
                            <li>Helped expanded thier digital <A href="https://data.cityofrochester.gov/">GIS library</A></li>
                            <li>Created an <A href="https://developers.arcgis.com/javascript/latest/">ESRI</A> Map Widget for <A href="https://www.mendix.com/">Mendix</A>, thier low code/no code enviornment</li>
                            <li>Worked with the full array of Microsoft products</li>
                        </ul>
                    </ul>

                    <li>US Army Futures Command <A href="https://armyfuturescommand.com/software-factory/">Software Factory</A><br/>
                        In partnership with <A href="https://tanzu.vmware.com/content/us-army-software-factory">VMware Tanzu</A>
                    </li>
                    <ul>
                        <li>Full Stack Engineer</li>
                        <li>January 2021 to December 2022 - 2 years</li>
                        <ul>
                            <li><A href="https://www.agilealliance.org/agile101/">Agile</A> <A href="https://www.easyagile.com/blog/software-development-methodologies/#lean-software-development-methodology">Lean methodology</A><br/>
                                with <A href="https://www.agilealliance.org/glossary/xp/">Extreme Programming</A> (XP) framework<br/>
                                and <A href="https://trunkbaseddevelopment.com/#scaled-trunk-based-development">Trunk-Based Development</A></li>
                            <li>Java Spring Backend with TypeScript React MUI Frontend</li>
                        </ul>
                    </ul>

                    <li>US Army</li>
                    <ul>
                        <li><A
                            href="https://www.goarmy.com/careers-and-jobs/career-match/mechanics-engineering/design-develop/12y-geospatial-engineer.html">Geospatial
                            Engineer</A> (12Y)
                        </li>
                        <li> August 2017 to August 2024 - 7 Years total</li>
                        <ul>
                            <li>Creates maps using GIS</li>
                            <li>Manage large groups of data and databases</li>
                            <li>Coordinate and manage several projects and team members simultaneously</li>
                        </ul>
                    </ul>
                </ul>
            </Block>

            <HR hue={hue} />

            <Block>
                <SubTitle hue={hue}>Software:</SubTitle>
                <ULColumns colNum={3}>
                    <li>Trello</li>
                    <li>Slack</li>
                    <li>Visual Studios</li>
                    <li>VSCode</li>
                    <li>IntelliJ</li>
                    <li>Miro</li>
                    <li>Netbeans</li>
                    <li>Eclipse</li>
                    <li>ArcMap</li>
                    <li>ArcPro</li>
                    <li>ArcPortal</li>
                    <li>ArcServer</li>
                </ULColumns>
            </Block>

            <Block>
                <SubTitle hue={hue}>Platforms:</SubTitle>
                <ULColumns colNum={2}>
                    <li>GitHub and GitLab</li>
                    <li><A href="https://vercel.com">Vercel</A></li>
                    <li><A href="https://www.heroku.com">Heroku</A></li>
                    <li>Google Domains</li>
                    <li>PostgreSQL</li>
                    <li>MongoDB</li>
                    <li>Amazon Web Sevices (AWS)</li>
                    <li>Kubernetes</li>
                    <li>OAuth 2v</li>
                    <li>Docker</li>
                </ULColumns>
            </Block>

            <Block>
                <SubTitle hue={hue}>Proficient with:</SubTitle>
                <ULColumns colNum={3}>
                    <li>Java</li>
                    <li>Spring</li>
                    <li>TypeScript</li>
                    <li>React</li>
                    <li>Material UI (MUI)</li>
                    <li>Python <br/>6+ years</li>
                    <li>C#</li>
                    <li>HTML</li>
                    <li>CSS</li>
                    <li>PostgreSQL</li>
                    <li>JavaScript</li>
                </ULColumns>
            </Block>

            <Block>
                <SubTitle hue={hue}>Familiar with:</SubTitle>
                <ULColumns colNum={2}>
                    <li>.Net Framework<br/>
                    Visual Basic</li>
                    <li>C++</li>
                    <li>QBasic</li>
                    <li>Bash</li>
                    <li>Docker</li>
                    <li>Kubernetes</li>
                    <li>Lua</li>
                    <li>Leaflet and Mapbox</li>
                </ULColumns>
            </Block>

            <Block>
                <SubTitle hue={hue}>Test Libraries:</SubTitle>
                <ul>
                    <li>JUnit</li>
                    <li>Jest</li>
                    <li>Cypress</li>
                </ul>
            </Block>

            <HR hue={hue}/>

            <Block>
                <SubTitle hue={hue}>Libraries I Have Written For TypeScript React</SubTitle>
                <ul>
                    <li><A href="https://www.npmjs.com/package/ts-jsx-table">
                        Tables
                    </A></li>
                    <li><A href="https://www.npmjs.com/package/ts-jsx-tabs">
                        Tabs
                    </A></li>
                    <li><A href="https://www.npmjs.com/package/ts-jsx-draggable">
                        Drag and Drop interactions
                    </A></li>

                </ul>
            </Block>

            <Block>
                <SubTitle hue={hue}>Web Pages I've Created</SubTitle>
                <ul>
                    <li><A href="https://www.tinytowns.schoonover.codes/">
                        Tiny Towns Card Randomizer
                    </A></li>
                    <li><A href="https://www.scutes.schoonover.codes/">
                        Scute Swarm Calculator for MTG
                    </A></li>
                    <li><A href="https://github.com/Schoony415/HomePage">
                        This one    
                    </A></li>
                </ul>
            </Block>

            <Block>
                <SubTitle hue={hue}>Repos Worth Remembering</SubTitle>
                <ul>
                    <li><A href="https://github.com/Schoony415">
                        My Own
                    </A></li>
                    <li><A href="https://gist.github.com/MattIPv4/045239bc27b16b2bcf7a3a9a4648c08a">
                        Beees
                    </A></li>
                </ul>
            </Block>
            {/*<p>"You're a CSS Genius" - Luke S. Full Stack Engineer Extrodinair</p>*/}

            {/* <DisplayColorBoxes hue={hue}/> */}

        </main>
        <footer style={{
            display: "inline-flex",
            backgroundColor: palette.Complementary.secondary,
            color: palette.Complementary.tertiary,
        }}>
            <div id="left-align">
                Author: Joshua Schoonover
            </div>
            <div id="right-align">
                Contact: <A href="https://www.linkedin.com/in/j-schoonover"><img src={inLogo} alt="" style={{height:'1rem', width:'1rem'}}/> J-Schoonover</A>
            </div>
        </footer>
    </>)
}

