import styled from "@emotion/styled";
import { CSSObject } from "@emotion/react";
import palette, {Hue} from "./palette";

const shadow = (thickness, color) => ({textShadow: `-${thickness}px ${thickness}px ${thickness}px ${color}, ${thickness}px ${thickness}px ${thickness}px ${color}, ${thickness}px -${thickness}px ${thickness}px ${color}, -${thickness}px -${thickness}px ${thickness}px ${color}`})

export const Title = styled("h1")(({hue}: { hue: Hue }) => ({
    color: palette.Spread[hue].tertiary,
    ...shadow(3, palette.Spread[hue].quaternary),
}))

export const SubTitle = styled("h3")(({hue}: { hue: Hue }) => ({
    color: palette.Spread[hue].quinary,
}))

export const Block = styled("div")(() => ({
    margin: '1rem auto 0 auto',
    // width: 'max-content', // causes no wordwrap on bullets 
    textAlign:'left',

} as CSSObject ))

// todo find a better type than any for the props
export const ULColumns = styled("ul")((props:any&{colNum:number})=>({
    columnCount: props.colNum,
    columnWidth: '10rem',
    columnGap: '1rem',
    alignContent: 'center',
    display: 'flow',
    padding: '0',
}))

export const A = styled('a')(() => ({
    /*
    a:active 	It is used to add style to an active element.
    a:hover 	It adds special effects to an element when the user moves the mouse pointer over the element.
    a:link 	It adds style to the unvisited link.
    a:visited 	It adds style to a visited link.
     */
    ':link': {
        color: palette.Complementary.quaternary,
    },
    ':visited': {
        color: palette.Complementary.quinary,
    }
}))

export const HR = styled('hr')(({hue}:{hue:Hue})=>({
    borderColor: palette.Spread[hue].quaternary,
    borderWidth: '1px',
    borderStyle: 'solid',
    width: '75%',
}))
